import {get, post} from "@/api/axios";

export const list = params => get('/web/house/view/list', params)

/**
 * /web/house/view/view/{id} get
 */
export const view = params => get('/web/house/view/view/' + params.id, '')

/**
 * /web/house/view/remark
 */
export const remark = params => post('/web/house/view/remark', params)

/**
 * /web/house/view/remark/list
 */
export const remarkList = params => get('/web/house/view/remark/list', params)