<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
    </avue-crud>
  </div>
</template>

<script>
import { remarkList } from "@/api/house/view";

export default {
  name: "record",
  data(){
    return{
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '带看记录',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: '记录内容',
            prop: 'remark'
          },
          {
            label: '记录人',
            prop: 'username'
          },
          {
            label: '记录时间',
            prop: 'createTime'
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      remarkList({
        id: this.$route.query.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>